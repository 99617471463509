// Copyright © 2022 Move Closer

import { Component, Prop, Vue } from 'vue-property-decorator'

import { MosaicModuleAddonSize, MosaicModuleAddonType } from '../Mosaic.contracts'
import { ResolvesRelatedAsync } from '../../../../services'
import { IEventbus } from '@movecloser/front-core'
import { Link } from '@movecloser/ui-core'

/**
 * AbstractAddon component that every MosaicAddon UI **SHOULD** extend
 */
@Component<AbstractAddonUi>({ name: 'AbstractAddonUi' })
export class AbstractAddonUi extends Vue {
  @Prop({ type: String, required: true })
  public readonly type!: MosaicModuleAddonType

  @Prop({ type: String, required: true })
  public readonly size!: MosaicModuleAddonSize

  @Prop({ type: String, required: false })
  public readonly title?: string

  @Prop({ type: String, required: false })
  public readonly description?: string

  @Prop({ type: Object, required: false })
  public readonly link?: Link

  @Prop({ type: Object, required: true })
  public readonly relatedService!: ResolvesRelatedAsync

  @Prop({ type: Object, required: true })
  public readonly eventBus?: IEventbus

  public get hasText (): boolean {
    return !!this.title && !!this.description
  }
}
