
































































/// <reference types="youtube" />

import { Component, Prop, Watch } from 'vue-property-decorator'
import { BootstrapButton, BootstrapImage } from '@movecloser/ui-core'

import { toBootstrapImageProps } from '../../../../../support'

import { EmbedAddon } from './Embed.contracts'
import { AbstractAddonUi } from '../AbstractAddon.ui'
import { AnyObject } from '@movecloser/front-core'
import {
  Image,
  ImageFile,
  ImageRatio,
  UiYTPlayerStateChangedEventPayload
} from '../../../../../models'
import { isRelated } from '../../../../../services'
import { YouTubeIframeLoader } from '../../../Embed/ui/versions/youtube/helpers'
import CloseIcon from '../../../Embed/ui/versions/youtube/helpers/icons/CloseIcon.vue'
import PlayIcon from '../../../Embed/ui/versions/youtube/helpers/icons/PlayIcon.vue'
import { UiHeading } from '../../../../atoms/UiHeading/UiHeading.vue'

/**
 * UI component for the `MosaicModuleAddonType.Heading`.
 */
@Component<EmbedAddonUi>({
  name: 'EmbedAddonUi',
  components: { BootstrapButton, BootstrapImage, CloseIcon, PlayIcon, UiHeading },
  async prefetch (): Promise<void> {
    await this.fetchRelated()
  },
  mounted (): void {
    if (this.$refs.video && this.$refs.video instanceof Element) {
      this.videoAttrId = this.$refs.video.getAttribute('id') ?? 'video'
    }
    this.registerListeners()
  }
})
export class EmbedAddonUi extends AbstractAddonUi {
  @Prop({ type: Object, required: false })
  public readonly thumbnail?: EmbedAddon['thumbnail']

  @Prop({ type: String, required: true })
  public readonly videoId!: EmbedAddon['videoId']

  /**
   * Determines whether the YouTube player is active.
   */
  public isYoutubePlayerActive: boolean = false

  /**
   * YouTube player instance.
   */
  private player: AnyObject | null = null

  /**
   * Ready to use `Image` object, resolved using `RelatedService`.
   */
  public resolvedThumbnail: Image | null = null

  /**
   * Very important for SSR.
   */
  private videoAttrId: string = this.$id() + 'video'
  private labelId: string = this.$id('title')

  private imageRatio = ImageRatio.Original

  public get image (): Image {
    if (this.resolvedThumbnail === null) {
      return this.youTubeCover
    }

    return this.resolvedThumbnail
  }

  public get youTubeCover (): Image {
    return {
      src: `https://img.youtube.com/vi/${this.videoId}/0.jpg`,
      alt: 'YouTube cover video'
    }
  }

  public async fetchRelated (): Promise<void> {
    await this.resolveThumbnail()
  }

  /**
   * Focuses the close button after modal is opened.
   */
  public focusOnInit (): void {
    this.$nextTick(() => {
      const elementToFocus = (this.$refs.modalClose as HTMLDivElement)

      if (elementToFocus) {
        elementToFocus.focus()

        this.trapFocus(true)
      }
    })
  }

  /**
   * Determines whether the `videoId` prop has been defined and has content.
   */
  public get hasVideoId (): boolean {
    return typeof this.videoId === 'string' && this.videoId.length > 0
  }

  private onKeyDown (event: KeyboardEvent): void {
    if (!this.isYoutubePlayerActive) {
      return
    }

    if (event.key === 'Escape') {
      this.closeModal()
    }
  }

  /**
   * Handles the `@click` event on the "play" button.
   */
  public onPlayBtnClick (): void {
    if (this.player !== null) {
      return this.showModal()
    }

    this.initPlayer(true)
  }

  /**
   * Pause youtube video.
   */
  public pauseVideo (): void {
    if (!this.player) {
      return
    }

    this.player.pauseVideo()
  }

  /**
   * Play youtube video.
   */
  public playVideo (): void {
    if (!this.player) {
      return
    }

    this.player.playVideo()
    this.focusOnInit()
  }

  /**
   * Close modal.
   */
  public showModal (): void {
    this.isYoutubePlayerActive = true
    this.playVideo()
  }

  /**
   * Close modal.
   */
  public closeModal (): void {
    this.isYoutubePlayerActive = false
    this.pauseVideo()
    this.trapFocus(false)

    // focus trigger element after closing the video
    this.$nextTick(() => {
      if (this.$refs.modalTrigger && '$el' in this.$refs.modalTrigger && this.$refs.modalTrigger.$el instanceof Element) {
        (this.$refs.modalTrigger.$el as HTMLButtonElement).focus()
      }
    })
  }

  /**
   * Register listener on modal wrapper.
   */
  public registerListeners (): void {
    if (!this.$refs.modalWrapper) {
      return
    }

    (this.$refs.modalWrapper as HTMLElement).addEventListener('click', () => {
      if (!this.isYoutubePlayerActive) {
        return
      }

      this.closeModal()
    })
    window.addEventListener('keydown', this.onKeyDown.bind(this))
  }

  private trapFocus (doTrap: boolean): void {
    const FOCUSABLE_ELEMENT_SELECTORS = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, [tabindex="0"], [contenteditable]'
    const wrapper = this.$refs.modalWrapper as HTMLDivElement
    const focusableElements = wrapper.querySelectorAll(FOCUSABLE_ELEMENT_SELECTORS)

    // There can be containers without any focusable element
    if (focusableElements.length > 0) {
      const firstFocusableEl = focusableElements[0] as HTMLElement
      const lastFocusableEl = focusableElements[focusableElements.length - 1] as HTMLElement

      const keyboardHandler = function keyboardHandler (e: KeyboardEvent) {
        if (e.key !== 'Tab') {
          return
        }

        if (e.shiftKey && document.activeElement === firstFocusableEl) {
          e.preventDefault()
          lastFocusableEl.focus()
        } else if (!e.shiftKey && document.activeElement === lastFocusableEl) {
          e.preventDefault()
          firstFocusableEl.focus()
        }
      }

      if (doTrap) {
        window.addEventListener('keydown', keyboardHandler)
      } else {
        window.removeEventListener('keydown', keyboardHandler)
      }
    }
  }

  /**
   * Watch is 'isYoutubePlayerActive' state changed.
   */
  @Watch('isYoutubePlayerActive')
  private onPlayerStatusChanged () {
    const body = document.body

    if (!body) {
      return
    }

    body.style.overflowY = this.isYoutubePlayerActive ? 'hidden' : ''
  }

  /**
   * Initialises the YouTube player.
   *
   * @param autoplay - Determines whether the video should start playing
   *   immediately after the player has been initialised.
   * @param muted - Determine whether the video should be started with volume 0.
   */
  private initPlayer (autoplay: boolean = false, muted: boolean = false): void {
    if (!this.hasVideoId || typeof this.eventBus === 'undefined') {
      return
    }

    this.eventBus.handle(
      'ui:yt-player.state-changed',
      (event: UiYTPlayerStateChangedEventPayload) => {
        if (this.player === null || typeof event.payload === 'undefined') {
          return
        }

        const { player, state } = event.payload

        if (!player || player.id === this.player.id) {
          return
        }

        if (state === 1) { // YT.PlayerState.PLAYING
          this.player.pauseVideo()
        }
      }
    )

    YouTubeIframeLoader.load(() => {
      if (!window.YT) {
        return
      }

      this.player = new window.YT.Player(this.videoAttrId, {
        height: '100%',
        width: '100%',
        videoId: this.videoId,
        playerVars: {
          enablejsapi: 1,
          disablekb: 1,
          origin: window.location.origin
        },
        host: `${window.location.protocol}//www.youtube.com`,
        events: {
          onReady: ({ target }) => {
            if (!autoplay || !this.player) {
              return
            }

            if (muted) {
              target.mute()
            }

            target.playVideo()
            this.focusOnInit()
          },
          onStateChange: (event) => {
            if (typeof this.eventBus === 'undefined') {
              return
            }
            const { data: state } = event

            if (typeof state !== 'number') {
              console.warn(`YT.Player.onStateChange(): Expected [event.data] to be a type of [number], but got [${typeof state}]!`)
              return
            }

            if (this.player === null) {
              console.warn('YT.Player.onStateChange(): FATAL! [this.player] is [null]!')
              return
            }

            const payload: UiYTPlayerStateChangedEventPayload['payload'] = {
              player: this.player,
              state
            }

            this.eventBus.emit('ui:yt-player.state-changed', payload)
          }
        }
      })
    })

    this.isYoutubePlayerActive = true
  }

  /**
   * Resolves the thumbnail image, if any.
   */
  private async resolveThumbnail (): Promise<void> {
    if (typeof this.thumbnail === 'undefined' || !isRelated(this.thumbnail)) {
      return
    }

    let imageFile: ImageFile
    try {
      imageFile = await this.relatedService.resolve(this.thumbnail) as unknown as ImageFile
    } catch (e) {
      console.warn(e)
      return
    }

    this.resolvedThumbnail = toBootstrapImageProps(imageFile, this.imageRatio)
  }
}

export default EmbedAddonUi
